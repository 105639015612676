// extracted by mini-css-extract-plugin
export var antSteps = "nowrap-module--ant-steps--xKkCh";
export var antStepsDot = "nowrap-module--ant-steps-dot--2MyWh";
export var antStepsHorizontal = "nowrap-module--ant-steps-horizontal--kbtVX";
export var antStepsItemDescription = "nowrap-module--ant-steps-item-description--5yg0d";
export var antStepsItemIcon = "nowrap-module--ant-steps-item-icon---izpd";
export var antStepsItemTitle = "nowrap-module--ant-steps-item-title--1RTC+";
export var antStepsSmall = "nowrap-module--ant-steps-small--9NK0H";
export var antTypography = "nowrap-module--ant-typography--NSmQ3";
export var hideOnReducedMotion = "nowrap-module--hideOnReducedMotion---EdUj";
export var noBullet = "nowrap-module--noBullet--2WgPs";
export var nowrap = "nowrap-module--nowrap--4mBre";
export var showOnReducedMotion = "nowrap-module--showOnReducedMotion--VY-lF";
export var tweetBox = "nowrap-module--tweet-box--UBKvv";